<template>
  <div>
    <v-app-bar app max-height="65" :dense="!!scrollY" elevation="2" class="darkenGrey">
      <v-app-bar-nav-icon
        v-if="userDetails && userDetails.role !== 2"
        v-model="drawer"
        @click="drawer = !drawer"
        class="white--text mt-1"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="font-weight-black cursor-pointer white--text px-2 pt-1">
        YelagiriGuide
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-layout row wrap align-center justify-end>
        <v-flex lg1 md1 sm1 xs5 class="text-center pr-sm-12">
          <v-menu transition="slide-x-transition" bottom nudge-bottom="17">
            <template #activator="{ on, attrs }">
              <v-avatar :size="$vuetify.breakpoint.xsOnly ? 33 : 39" v-bind="attrs" v-on="on">
                <img :src="`${imgbaseurl}/profiles/${userDetails && userDetails.profile ? userDetails.profile : 'profile.webp'}`" alt="Profile">
              </v-avatar>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in avatarMenuOptions" :key="index" @click="item.atClick && (item.atClick)()">
                <v-list-item-icon>
                  <v-icon size="22" color="grey darken-3"> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="ml-n2 subtitle-2 font-weight-medium grey--text text--darken-3"> {{ item.title }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-flex>
      </v-layout>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary class="grey lighten-5">
      <v-img :src="`${imgbaseurl}/profiles/yg.png`" alt="YelagiriGuide" height="200" width="100%"></v-img>
      <!-- <img :src="`${imgbaseurl}/profiles/${userDetails && userDetails.profile ? userDetails.profile : 'profile.webp'}`" alt="Profile"> -->
      <v-list dense class="py-0">
        <v-list-item-group v-model="group" active-class="grey--text text--darken-4">
          <v-list-item v-for="(item, index) in sideMenuItems" :key="index" class="px-5 py-1" @click="$route.fullPath !== item.to ? $router.push(item.to || '/') : ''">
            <v-list-item-icon class="ml-1 ml-lg-0 mr-5">
              <v-icon :size="item.iconSize || 24" color="grey darken-3"> {{ item.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-2 font-weight-bold"> {{ item.title }} </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- <template #append>
        <v-btn block> Login </v-btn>
      </template> -->
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data () {
    return {
      drawer: false,
      group: null,
      scrollY: 0,
      adminMenuItems: [{
        title: 'Tenants',
        icon: 'mdi-file-table-box-multiple',
        iconSize: 24,
        to: '/tenants'
      }, {
        title: 'User Queries',
        icon: 'mdi-account-alert',
        iconSize: 25,
        to: '/user-queries'
      }, {
        title: 'Email subscriptions',
        icon: 'mdi-email-alert',
        iconSize: 25,
        to: '/email-subscriptions'
      }],
      tenantMenuItems: [{
        title: 'Calendar',
        icon: 'mdi-calendar',
        iconSize: 26,
        to: '/calendar'
      }],
      userMenuItems: [{
        title: 'Calendar',
        icon: 'mdi-calendar',
        iconSize: 25,
        to: '/calendar'
      }]
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      this.scrollY = scrollY
    })
  },
  computed: {
    sideMenuItems () {
      return (this.userDetails) ? (this.userDetails.role === 1)
        ? this.adminMenuItems : (this.userDetails.role === 2)
        ? this.tenantMenuItems : this.userMenuItems : []
    },
    avatarMenuOptions () {
      return [
        // { title: 'Bookings', icon: 'mdi-calendar', atClick: () => this.$route.fullPath !== '/bookings' ? this.$router.push('/bookings') : '' },
        { title: 'Logout', icon: 'mdi-logout', atClick: this.logout }
      ]
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      this.$router.push('/')
    }
  }
}
</script>